import {SdkConnector} from '@/configurator/business-logic/sdk-connector';
import {getParentUrl} from '@/common/utils/helper';
import {getRequestData} from './get-request-data';
import {API_BASE} from './api-url';

export const createRoomleEntry = async (sdkConnector: SdkConnector, store: any, source: string, partListStringStart: string) => {
  const configurator = await sdkConnector.api;

  const requestData = {
    data: await getRequestData(sdkConnector, store, partListStringStart),
    hidePrice: false,
  };

  if (store.state.uiState.initData.customConfig.general.look === 'default' || store.state.uiState.initData.customConfig.general.hidePrice === true) {
    requestData.hidePrice = true;
  }
  requestData.data.webTeaser = store.state.uiState.initData.customConfig.general.partListDescription;

  const {image} = await configurator.preparePerspectiveImage();
  requestData.data.thumbnail = image;

  let shareUrl: string | URL | null = getParentUrl(store.state.coreData.parentUrl);
  const currentConfiguration = await sdkConnector.saveCurrentConfiguration();
  const configurationId = currentConfiguration.id;
  if (shareUrl && configurationId) {
    const params = new URLSearchParams(shareUrl.search);
    params.append('configuratorId', configurationId);
    shareUrl.search = '';
    shareUrl = new URL(shareUrl.href + '?' + params.toString());
  } else {
    shareUrl = new URL(`${API_BASE}/configurator/?configuratorId=${configurationId}`);
  }
  shareUrl = shareUrl.toString();
  const baseUrl = new URL(shareUrl);
  baseUrl.search = '';
  requestData.data.baseUrl = baseUrl.toString();

  requestData.data.source = source;

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestData),
  };

  try {
    // @ts-ignore
    const response = await fetch(`${API_BASE}/api/v1/roomle/add-roomle-entry`, requestOptions);
    const data = await response.json();

    return data;
  } catch (e) {
    window.parent.postMessage('error adding entry', API_BASE);
  }
};
