












import Vue from 'vue';
import Spinner from '@/configurator/components/utils/Spinner.vue';

export default Vue.extend({
  name: 'Primary.vue',

  components: {
    Spinner,
  },

  props: {
    disabled: Boolean,
    onClick: Function,
  },

  data() {
    return {
      isRunning: false,
    };
  },

  computed: {
    isDisabled(): boolean {
      return this.disabled || this.isRunning;
    },
  },

  methods: {
    async clicked() {
      if (this.isDisabled) {
        return;
      }
      const value = this.onClick();
      if (value instanceof Promise) {
        this.isRunning = true;
        await value;
        this.isRunning = false;
      }
    },
  },

});
