



























import Vue, {PropOptions} from 'vue';
// @ts-ignore
import SvgClose from '@/common/svgs/close.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';

type PopUpSize = 'big' | 'medium' | 'small' | 'from-right';
type PopUpAlignment = 'scene';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  props: {
    onClose: Function,
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isLightTheme: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      // value can be any because we do not know what the user inputs
      // templates in vue still have no typechecking
      validator(value: any) {
        const possibleValues: PopUpSize[] = ['big', 'medium', 'small', 'from-right'];
        return possibleValues.indexOf(value) !== -1;
      },
    } as PropOptions<PopUpSize>,

    alignTo: {
      type: String,
      // value can be any because we do not know what the user inputs
      // templates in vue still have no typechecking
      validator(value: any) {
        const possibleValues: PopUpAlignment[] = ['scene'];
        return possibleValues.indexOf(value) !== -1;
      },
    } as PropOptions<PopUpAlignment>,
    center: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

    icons(): object {
      return {
        SvgClose,
      };
    },

    isBig(): boolean {
      return !this.size || this.size === 'big';
    },

    isMedium(): boolean {
      return this.size === 'medium';
    },

    isSmall(): boolean {
      return this.size === 'small';
    },

    isFromRight(): boolean {
      return this.size === 'from-right';
    },

    isAlignToScene(): boolean {
      return this.alignTo === 'scene';
    },
  },

  methods: {
    close() {
      this.onClose();
    },
  },
});
