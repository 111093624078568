































import Vue from 'vue';
import {Nullable} from '@/common/utils/types';
import {RapiTenant} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import CheckBox from '@/configurator/components/utils/CheckBox.vue';

export default Vue.extend({
  name: 'GdprEmailInput.vue',

  components: {
    CheckBox,
  },

  data() {
    return {
      email: '',
      gdprChecked: false,
      tenant: null as Nullable<RapiTenant>,
    };
  },

  computed: {
    showMail(): boolean {
      return !!this.tenant && !!this.tenant.label;
    },
    tenantName(): string {
      return this.tenant?.label || '';
    },
    gdprText(): string {
      return (this as any).t.t('global.gdpr-info').replace('<TENANT_NAME>', this.tenantName);
    },
    privacyUrl(): Nullable<string> {
      return this.tenant?.privacyUrl || null;
    },
    isValid(): boolean {
      return /\S+@\S+\.\S+/.test(this.email) && this.gdprChecked;
    },
  },

  methods: {
    onEmailChange(event: Event) {
      this.email = (event?.currentTarget as HTMLInputElement).value;
      this.callback();
    },
    onCheckBoxChange(event: InputEvent) {
      this.gdprChecked = (event?.currentTarget as HTMLInputElement).checked;
      this.callback();
    },
    callback() {
      if (this.isValid) {
        this.$store.commit(UI_STATE_MUTATIONS.SET_EMAIL, this.email);
      }
      this.$emit('change', this.isValid, this.email, this.gdprChecked);
    },
  },

  async mounted() {
    this.tenant = await this.$sdkConnector.getTenant();
    this.email = this.$store.state.uiState.email;
  },
});
