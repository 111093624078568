
















































































































import Vue from 'vue';
import PopUp from '@/configurator/components/utils/PopUp.vue';
import {Nullable} from '@/common/utils/types';
import {copyToClipboard} from '@/common/utils/dom';
// @ts-ignore
import SvgCheck from '@/common/svgs/check.svg';
// @ts-ignore
import SvgCopy from '@/common/svgs/copy.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import GdprEmailInput from '@/configurator/components/utils/GdprEmailInput.vue';
import PrimaryButton from '@/configurator/components/utils/PrimaryButton.vue';
import Spinner from '@/configurator/components/utils/Spinner.vue';
// import {SHARE_PLACEHOLDER} from '@/common/utils/init-data';
import {GA_CATEGORY} from '@/common/utils/google-analytics';
// import {createShareLinkFromUrl, isLocationJestTest, oneTimeInterval} from '@/common/utils/helper';
import {oneTimeInterval, getParentUrl} from '@/common/utils/helper';

import {getRequestData} from '@/common/utils/get-request-data';
import {createRoomleEntry} from '@/common/utils/create-roomle-entry';
import {API_BASE} from '@/common/utils/api-url';
import {getGeneralCustomConfig} from '@/common/utils/custom-config';
import {CORE_DATA_MUTATIONS} from '@/configurator/store/core-data';

// const SHARE_URL_USE_LOCATION_HREF = ['localhost', 'alpha', 'testServer'];

export default Vue.extend({
  components: {
    PopUp,
    SvgIcon,
    GdprEmailInput,
    PrimaryButton,
    Spinner,
  },

  data() {
    return {
      perspectiveImage: null as Nullable<string>,
      configurationId: null as Nullable<string>,
      shareUrl: null as Nullable<string>,
      email: '',
      gdprValid: false,
      copied: false,
      intervalId: null as Nullable<number>,
      urlGenerated: false,
      isLoading: false,
      shareId: null as Nullable<string>,
    };
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    copy() {
      if (this.shareId) {
        copyToClipboard(this.shareId);
        this.copied = true;
        if (this.intervalId) {
          return;
        }
        this.intervalId = oneTimeInterval(() => {
          if (this.intervalId) {
            this.copied = false;
            this.intervalId = null;
          }
        }, 1300);
      }
    },
    async save() {
      // make function sync and return promise if needed
      // this is needed so that "save" button does not show
      // loading spinner when we only copy to clipboard
      if (this.useEmail && this.configurationId && this.isValid && !this.urlGenerated) {
        return this._generateUrl();
      } else if (this.shareUrl) {
        const configurator = await this.$sdkConnector.api;

        const requestData = {
          data: await getRequestData(this.$sdkConnector, this.$store, (this as any).t.t('part-list.selected-config')),
          hidePrice: false,
        };

        if (this.$store.state.uiState.initData.customConfig.general.look === 'default' || this.$store.state.uiState.initData.customConfig.general.hidePrice === true) {
          requestData.hidePrice = true;
        }
        requestData.data.webTeaser = this.$store.state.uiState.initData.customConfig.general.partListDescription;

        const {image} = await configurator.preparePerspectiveImage();
        requestData.data.thumbnail = image;

        const baseUrl = new URL(this.shareUrl);
        baseUrl.search = '';
        requestData.data.baseUrl = baseUrl.toString();

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(requestData),
        };

        try {
          // @ts-ignore
          const response = await fetch(`${API_BASE}/api/v1/roomle/add-to-wishlist`, requestOptions);
          const data = await response.json();

          if (data.success) {
            this.shareId = `#${data.entryId}`;
            window.parent.postMessage(
              {
                action: 'success save wishlist',
                currency: this.$store.state.coreData.currencySymbol,
                price: this.$store.state.coreData.price,
              },
              API_BASE,
            );
          } else {
            window.parent.postMessage('error save wishlist', API_BASE);
          }
        } catch (e) {
          window.parent.postMessage('error save wishlist', API_BASE);
        }

        // this.copy();
      }
    },
    async _generateUrl() {
      const currentConfiguration = await this.$sdkConnector.saveCurrentConfiguration();
      const rapiAccess = await this.$sdkConnector.getRapiAccess();
      await rapiAccess.shareConfiguration(currentConfiguration.id, this.email, 3);
      this.$analytics.ga.trackEvent('ConfigurationSharedByMail', GA_CATEGORY.INTERACTION, currentConfiguration.id);
      await this.generateUrl();
      this.urlGenerated = true;
    },
    onEmailChange(isValid: boolean, email: string, _gdprChecked: boolean) {
      this.gdprValid = isValid;
      this.email = email;
    },
    async generateUrl(): Promise<void> {
      // const configurator = await this.$sdkConnector.api;
      // const shareUrlInitParam = this.$store.state.uiState.initData.deeplink;
      // if (shareUrlInitParam) {
      //   if (!shareUrlInitParam.includes(SHARE_PLACEHOLDER)) {
      //     console.warn('the provided short url has no ' + SHARE_PLACEHOLDER + ' placeholder');
      //   }
      //   this.shareUrl = shareUrlInitParam.replace(SHARE_PLACEHOLDER, this.configurationId);
      // } else {
      //   if (SHARE_URL_USE_LOCATION_HREF.includes(process.env.VUE_APP_SHARE_URL_TYPE as any) && !isLocationJestTest()) {
      //     const shareUrl = createShareLinkFromUrl(location.href);
      //     this.shareUrl = shareUrl.replace(SHARE_PLACEHOLDER, this.configurationId!);
      //   } else {
      //     this.shareUrl = await configurator.getShortUrlOfCurrentConfiguration();
      //   }
      // }
      let shareUrl = getParentUrl(this.$store.state.coreData.parentUrl);
      if (shareUrl && this.configurationId) {
        const params = new URLSearchParams(shareUrl.search);
        params.append('configuratorId', this.configurationId);
        shareUrl.search = '';
        shareUrl = new URL(shareUrl.href + '?' + params.toString());
      } else {
        shareUrl = new URL(`${API_BASE}/configurator/?configuratorId=${this.configurationId}`);
      }

      this.shareUrl = shareUrl.toString();
    },
    redirect() {
      window.parent.postMessage('redirect wishlist', API_BASE);
    },
    async requestPrice() {
      this.onClose();

      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage(this.getLook() === 'default' ? 'open request price popup' : 'open request expert popup', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'request price', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry request price popup',
            entryId: data.entryId,
            entryImage: data.entryImage,
            currency: this.$store.state.coreData.currencySymbol,
            price: this.$store.state.coreData.price,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
  },

  computed: {
    icons(): object {
      return {
        SvgCheck,
        SvgCopy,
      };
    },
    isValid(): boolean {
      return this.gdprValid;
    },
    saveButtonEnabled(): boolean {
      return this.isValid || (!this.useEmail && !!this.shareId);
    },
    useEmail(): boolean {
      return this.$store.state.uiState.initData.emails;
    },
  },

  async mounted() {
    this.isLoading = true;

    await this.$sdkConnector.waitForLoad();
    const configurator = await this.$sdkConnector.api;
    const currentConfiguration = await this.$sdkConnector.saveCurrentConfiguration();
    this.configurationId = currentConfiguration.id;
    const {image} = await configurator.preparePerspectiveImage();
    this.perspectiveImage = image;
    // this.isLoading = true;
    await this.generateUrl();
    await this.save();
    this.isLoading = false;
  },

  destroyed() {
    this.perspectiveImage = null;
  },

  props: {
    onClose: Function,
  },

});
