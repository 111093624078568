



















import Vue from 'vue';

export default Vue.extend({

  props: {
    name: String,
    onChange: Function,
  },

});
